import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { AfterPageProps } from '../../@sprinx/react-after-razzle';
import { useApiClient } from '../../api/appState';
import useLoginHandler from '../../api/auth/useLoginHandler';
import { customerNotificationOpenState } from '../../api/customerNotification';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import LoginPageLogo from './LoginPageLogo';
import LoginRegistrationDialog from './components/LoginRegistrationDialog';

export type LoginPageProps = AfterPageProps;
export type LoginPageClassNameKey =
  | 'root'
  | 'content'
  | 'headline'
  | 'fields'
  | 'actions'
  // | 'logo'
  | 'loginButton'
  | 'forgotFieldsContainer'
  | 'registrationButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<LoginPageClassNameKey, {}>({
    root: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
    },
    content: {
      boxShadow: theme.shadows[4],
    },
    headline: {
      backgroundColor: 'white',
      padding: theme.spacing(2.5, 3),
      display: 'flex',
      justifyContent: 'center',
    },
    fields: {
      padding: theme.spacing(2, 3),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      padding: theme.spacing(2, 3),
      '& > a': {
        cursor: 'pointer',
        marginRight: 'auto',
      },
    },
    forgotFieldsContainer: {
      padding: theme.spacing(0, 3, 3, 3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    loginButton: {
      backgroundColor: '#002969',
      color: 'white',
      '&:hover': {
        backgroundColor: '#003485',
      },
    },
    registrationButton: {
      backgroundColor: theme.palette.appBar.background,
      '&:hover': {
        backgroundColor: lighten(theme.palette.appBar.background, 0.2),
      },
    },
  }),
);

const initialValues = {
  username: '',
  password: '',
  forgotUser: '',
};

interface Values {
  forgotUser: string;
  password: string;
  username: string;
}

const LoginPage: React.FC<LoginPageProps> = ({ history }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<Values>(initialValues);
  const [error, setError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openRegistration, setOpenRegistration] = React.useState<boolean>(false);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();
  const setNotificationOpen = useSetRecoilState(customerNotificationOpenState);
  const routeUrlFactory = useRouteUrlFactory();
  const handleChange = React.useCallback(
    (fieldName: keyof Values) => (value: string | undefined) => {
      setValues((ps) => ({ ...ps, [fieldName]: value }));
    },
    [],
  );

  const handlePasswordReset = React.useCallback(() => {
    if (values.forgotUser) {
      apiClient
        .post<{ email: string }, { username: string }>('/v1/users/renew-password', {
          username: values.forgotUser,
        })
        .then(
          ({ email }) => {
            showMessage({ message: t('loginPage/messages/passwordSent', { email: email || '' }), variant: 'success' });
            setValues(initialValues);
            setOpen(false);
          },
          () => {
            showMessage({ message: t('loginPage/messages/errorUpdatingPassword'), variant: 'error' });
          },
        );

      // ApiClient
      // .then((res) => {
      //    showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
      //    setValues(initialValues);
      //    setOpen(false);
      // })
      // .catch(() => showMessage({ message: 'Neplatné uživatelské jméno', variant: 'error' }))
      // showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
    }
  }, [values, showMessage, apiClient]);

  const handleLogin = useLoginHandler(
    () => {
      setNotificationOpen(true);
      // const afterLoginPath = location?.search
      //   ? (qs.parse(location?.search, { ignoreQueryPrefix: true })?.from as string)
      //   : routeUrlFactory('customerProfile');
      // TODO: add from?
      const afterLoginPath = routeUrlFactory('customerProfile');

      history.replace(afterLoginPath);
    },
    (err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Login Error ', err);
      }
      setError(true);
    },
  );
  const t = useTranslateWithFallback();

  const handleOpenRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(true);
  }, []);

  const handleCloseRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(false);
  }, []);

  return (
    <>
      <Container className={classes.root}>
        <Container component='main' maxWidth='sm'>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleLogin(values.username, values.password);
            }}
          >
            <div className={classes.content}>
              <div className={classes.headline}>
                <LoginPageLogo />
              </div>
              <div className={classes.fields}>
                {error && (
                  <Alert severity='error'>
                    <strong>{t('loginPage/loginError')}</strong> {t('loginPage/wrongCredentials')}
                  </Alert>
                )}
                <TextField
                  type='text'
                  label={t('common/username')}
                  onChange={handleChange('username')}
                  value={values.username}
                  fullWidth
                  margin='normal'
                />
                <PasswordField
                  label={t('common/password')}
                  onChange={handleChange('password')}
                  value={values.password}
                  fullWidth
                  margin='normal'
                />
              </div>
              <div className={classes.actions}>
                <Button
                  onClick={handleOpenRegistrationDialog}
                  component={'a'}
                  className={classes.registrationButton}
                  variant='contained'
                >
                  {t('loginPage/register')}
                </Button>
                <Link component={'a'} onClick={() => setOpen((ps) => !ps)}>
                  {t('loginPage/forgotPassword')}
                </Link>
                <Button type='submit' className={classes.loginButton} variant='contained'>
                  {t('loginPage/login')}
                </Button>
              </div>
              <Collapse in={open}>
                <div className={classes.forgotFieldsContainer}>
                  <TextField
                    type='text'
                    label={t('loginPage/enterYourEmail')}
                    onChange={handleChange('forgotUser')}
                    value={values.forgotUser}
                    fullWidth
                    margin='normal'
                  />
                  <Button variant='contained' color='primary' size='small' onClick={handlePasswordReset}>
                    {t('common/send')}
                  </Button>
                </div>
              </Collapse>
            </div>
          </form>
        </Container>
      </Container>
      <LoginRegistrationDialog openDialog={openRegistration} onClose={handleCloseRegistrationDialog} />
    </>
  );
};

LoginPage.displayName = 'LoginPage';

export default LoginPage;
